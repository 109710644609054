import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CardDash({icon, title, color, value, tooltip}){
    return(
        <div className="card_container">
            <FontAwesomeIcon style={{background: color}} className="icon_card_dash" icon={icon} />
            <div className="display_end">
                <div>
                    <div className="sub_card">{title} 
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                {tooltip}
                            </Tooltip>
                        }
                        >
                            <FontAwesomeIcon className="quest__dash ms-1" icon={faQuestion} />
                        </OverlayTrigger>
                    </div>
                    <div className="value_card">{value}</div>
                </div>
            </div>
        </div>
    )
}

export default CardDash;