import { Col, Container, Row, Button } from 'react-bootstrap';
import '../assets/css/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faHome, faMoneyBill, faUnlock, faVault } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import CardDash from '../components/Card';
import {useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPrice
} from '../redux/reducers/userReducer';
import LogIn from '../components/LogIn';
import PopUp from '../components/PopUp';

function Dashboard(){
    const navigate = useNavigate();
    let { ref_code } = useParams();
    const [modal, setModal] = useState(false);
    const [popup, setPopUp] = useState(false);

    const {user, price} = useSelector(state => state.user);
    const dispatch = useDispatch();
    
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const setPriceOnce = useCallback(() => {
        const random = getRandomInt(100000, 500000);
        dispatch(setPrice(random))
    }, [dispatch])

    useEffect(() => {
        if(!price){
            setPriceOnce();
        }
    })

    function commafy( num ) {
        var str = num.toString().split('.');
        if (str[0].length >= 5) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    }

    const logIn = () => {
        setModal(true);
    }

    return(
        <Container className='container_dashboard'>
            <div className='floating_bg'>
                <div className='container__circle'>
                    <div className='circle_primary'/>
                </div>
            </div>

            <div style={{position: 'relative', zIndex: '10'}}>
                <div className='men_p'>
                    <FontAwesomeIcon onClick={() => ref_code ? navigate('/'+ref_code) : navigate('/')} className='cursor_pointer home_dash' icon={faHome} />  
                    <span className='ps-2 pe-2'>/</span>  
                    Dashboard
                </div>
                <div className='men_h'>
                    Dashboard
                </div>

                <div className='card_display'>
                    <CardDash icon={faCashRegister} title="Total Supply" color="black" value="69.42B $DTCoins" tooltip="The total supply of TrumpCoins token." />
                </div>
                <div className='display_center pt-5'>
                    {!user &&
                        <>
                            <Button onClick={() => logIn()} className='btn_cards mt-4'>LOG IN</Button>
                        </>
                    }
                    {user && 
                        <>
                            <div className="text_black men_h">Welcome back, {user.email}!</div>
                            <div className="text_black men_h">Total balance for unlock: </div>
                            <div className="text_black"> {price ? commafy(price) : 0} $DTCoins</div>
                            <Button onClick={() => {setPopUp(true)}} className='btn_cards  mt-4'>UNLOCK NOW</Button>
                        </>
                    }
                </div>
            </div>
            <LogIn modalShow={modal} setModalShow={setModal}/>
            <PopUp modalShow={popup} setModalShow={setPopUp} />
        </Container>
    )
}

export default Dashboard;