import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import '../assets/css/Navbar.css';
import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";

function NavBar(){
    const history = createBrowserHistory()
    let [ref_code, setRefCode] = useState(null);

    console.log(ref_code)
    useEffect(() => {
        let subpath = history.location.pathname.split('/')[1]
        if(subpath){
            if(subpath !== 'dashboard' && subpath !== 'terms' && subpath !== 'privacy'){
                setRefCode(subpath)
            }else{
                setRefCode(null)
            }
        }
    }, [history.location])

    return(
        <Navbar expand="sm" data-bs-theme="dark" className="bg-body-tertiary nav__top">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Offcanvas data-bs-theme="dark" id="basic-navbar-nav" placement="end"  aria-labelledby={`basic-label-navbar-nav`}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id='basic-label-navbar-nav'>
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Nav fill activeKey={history.location.pathname} className="me-auto">
                            <Nav.Link href={ref_code ? "/" +ref_code : "/"}>Home</Nav.Link>
                            <Nav.Link href={ref_code ? "/"+ref_code+"/dashboard" : "dashboard"}>Dashboard</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                   
                </Navbar.Offcanvas>

                <div className="d-flex">
                </div>
            </Container>
        </Navbar>
    )
}

export default NavBar;