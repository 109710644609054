import { Modal } from "react-bootstrap";
import '../index.css';

import {Button} from 'react-bootstrap';

function PopUp({modalShow, setModalShow}){

    return(
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            data-bs-theme="dark"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <p className="text_white men_h">Contact you agent for instructions</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PopUp;