import { Container, Navbar } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import '../assets/css/Navbar.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Footer(){
    const navigate = useNavigate();
    const location = useLocation();
    let [ref_code, setRefCode] = useState(null);

    useEffect(() => {
        let subpath = location.pathname.split('/')[1]
        if(subpath){
            if(subpath !== 'dashboard' && subpath !== 'terms' && subpath !== 'privacy'){
                setRefCode(subpath)
            }else{
                setRefCode(null)
            }
        }
    }, [location])
    return(
        <Navbar expand="sm" data-bs-theme="dark" className="bg-body-tertiary nav__bottom">
            <Container className="container_footer pt-2">
                <img className="img_footer" src={logo} alt="logo" />
                <h6 className="h6_footer">COLLECTOR GOLDEN MEMBER</h6>
                <p className="p_footer">This is a commemorative piece and is solely intended as memorabilia.</p>
                <p className="p_footer" style={{marginBottom: '0.1rem'}}>Copyright © 2024</p>
                {/* 
                <div className="terms_footer">
                    <p onClick={() => ref_code ? navigate(ref_code + '/privacy') : navigate('/privacy') }>Privacy Policy</p> 
                    <p> | </p>
                    <p onClick={() => ref_code ? navigate(ref_code + '/terms') : navigate('/terms')}>Terms & Conditions</p>
                </div>*/}
            </Container>  
        </Navbar>
    )
}

export default Footer;