import { Container } from "react-bootstrap";
import '../assets/css/Terms.css';

function PrivacyPolicy(){
    const website = 'www.PatriotsCards.com'
    const company = 'ASD, LLC'
    const mail = 'support@collecttrumpcards.com'
    return(
        <Container className="terms_container">
            <h1>Privacy Policy</h1>
            <div>Last Modified: April 2, 2024</div>
            <p>
                This website is owned and operated by {company}. We respect your privacy and understand that you have a right to know why we collect your personal information and what we do with it.  This Privacy Policy applies to information that identifies you as a person (“Personal Information”) that we collect through our Website (as defined below) as well as information we may collect offline. 
                <br/><br/>
                This policy describes the type of information we collect from you and/or that you may provide us when you visit and/or use this website and/or any of our mobile applications (individually or collectively, “Website”). “You/your/user(s)” means you as a user of our Website. 
                <br/><br/>
                By accessing the Website, you acknowledge this Privacy Policy and agree to be bound by the terms hereof, the Website Terms and Conditions, and any other terms or policies we post on the Website. If there is anything you do not understand, please email any inquiry to {mail}. If at any time you do not agree to this Privacy Policy, please do not use the Website or provide us with any Personal Information.  
                <br/><br/>
                We reserve the right to change or update this Privacy Policy by posting such changes or updates to the Website or emailing you notice of the changes. Amendments to this Privacy Policy will be posted at this URL and will be effective when posted. You can tell if this Privacy Policy has changed by checking the last modified date that appears at the end of this Privacy Policy. Your continued use of the Website following the posting of any amendment, modification or change shall constitute your acceptance thereof.  
                <br/><br/>

                <h6>YOUR SECURITY</h6>
                We strive to keep your Personal Information private and safe. We take commercially reasonable physical, technical, and administrative steps to maintain the security of Personal Information collected, including limiting the number of people who have physical access to database servers, as well as employing electronic security systems and password protections that guard against unauthorized access. 
                <br/><br/>
                Unfortunately, despite our best efforts, the transmission of data over the Internet cannot be guaranteed to be 100% secure. While we will use reasonable means to ensure the security of information you transmit through the Website, any transmission of Personal Information is at your own risk. We cannot guarantee that such information will not be intercepted by third parties, and we will not be liable for any breach of the security of your Personal Information resulting from causes or events that are beyond our control, including, without limitation, your own act or omission, corruption of storage media, defects in third-party data security products or services, power failures, natural phenomena, riots, acts of vandalism, hacking, sabotage, or terrorism, and we are not responsible for unauthorized circumvention of any privacy settings or security measures contained on the Website.
                <br/><br/>

                <h6>YOUR RIGHTS</h6>
                Various state laws provide you with certain rights as a consumer. If you are a resident of any of those states, you may have rights such as but not limited to: 
                <br/><br/>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                        The right to correct inaccuracies in your Personal Information
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                        The right to request that we delete your Personal Information (unless we have a legal obligation to maintain such information)
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                        The right to obtain a copy of your Personal Information from us that would allow you to transmit the data to another company
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                        The right to opt out of the use of your Personal Information for purposes of targeted advertising
                    </span>
                </span>
                <br/>
                We will not discriminate against you for exercising your privacy rights.
                <br/><br/>

                <h6>HOW TO EXERCISE YOUR RIGHTS</h6>
                If you believe you are a resident of any state that has passed applicable laws relating to your privacy rights, please send your request to exercise any of these rights to us at support@collectrumpcards.com.  
                <br/><br/>
                You may also fill out our online request form at support@collectrumpcards.com. 
                <br/><br/>
                If you wish to opt out of the use of your Personal Information for targeted advertising or remarketing, please click here.
                <br/><br/>

                <h6>CATEGORIES OF INFORMATION WE COLLECT</h6>
                We collect Personal Information in three ways. First, we collect Personal Information that you provide to us. Second, we collect certain Automated Information that may contain Personal Information when you use our Website. Third, we may collect Personal Information from public sources or from third parties who have obtained such Personal Information in compliance with applicable laws and have the legal authority to provide such Personal Information to us. 
                <br/><br/>
                We will only collect Personal Information which is adequate and reasonably necessary in relation to the purposes described in this Privacy Policy.
                <br/><br/>

                <h6>Personal Information You Provide To Us</h6>
                The Personal Information you provide to us is generally: 
                <br/><br/>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                        contact details including name, email, telephone number and shipping, billing address, including city and state 
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    login and account information
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    personal details and purchase history
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    personal preferences including your wish list as well as marketing preferences 
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    purchase history
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    payment or credit card information through our third party payment processor
                    </span>
                </span>
                <br/>
                We collect Personal Information directly from you when you provide it to us. This typically occurs when you: 
                <br/><br/>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    sign up for our email list
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    place an order with us online
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    send us an email or other communication
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    create an account with us
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    participate in our promotions, surveys, and/or contests
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    complete forms that may appear on our website
                    </span>
                </span>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    send us an email or other communication that contains any Personal Information
                    </span>
                </span>
                <br/>
                It is optional for you to engage in such activity, however, if you choose to do so, we may require that you provide us certain Personal Information. Depending upon the activity, some of the information we ask you to provide is required and some is voluntary. If you do not provide required information for a particular activity, you may not be permitted to engage in that activity.
                <br/><br/>
                <h6>Automated Information</h6>
                We also collect information, some of which may be Personal Information, through automated means when you visit our Website (“Automated Information”) such as: 
                <br/><br/>
                <span className="flex_row">
                    <span> &nbsp;•</span>
                    <span className="part__footer"> 
                    send us an email or other communication that contains any Personal Information
                    </span>
                </span>
            </p>
        </Container>
    )
}

export default PrivacyPolicy;