import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   price: null,
   user: null
};

const UserSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
      setPrice: (state, action) => {
        state.price = action.payload;
      },
      setUser: (state, action) => {
        state.user = action.payload;
      },
      logOutUser: (state, action) => {
        state.user = null;
      },
    }
});

export const {setPrice, setUser, logOutUser} = UserSlice.actions;

export default UserSlice.reducer;