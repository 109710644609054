import './assets/css/App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NavBar from './layouts/NavBar';
import Footer from './layouts/Footer';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { createBrowserHistory } from 'history';
import Dashboard from './pages/Dashboard';

function App() {
  const history = createBrowserHistory();

  return (
    <div className="App">
        <Routes history={history}>
          <Route
              path='/*'
              element={
                <>
                  <NavBar />
                  <div className='main_page'>
                    <Routes>
                      <Route path='/:ref_code?' element={<Home />} />

                      <Route path='/dashboard' element={<Dashboard />} />
                      <Route path='/:ref_code/dashboard' element={<Dashboard />} />

                      <Route path='/terms' element={<Terms />} />
                      <Route path='/privacy' element={<PrivacyPolicy />} />
                      <Route path='/:ref_code/terms' element={<Terms />} />
                      <Route path='/:ref_code/privacy' element={<PrivacyPolicy />} />
                    </Routes>
                  </div>
                  <Footer />
                </>
              }
          />
        </Routes>
    </div>
  );
}

export default App;
