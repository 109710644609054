import { Modal } from "react-bootstrap";
import '../index.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    MDBContainer,
    MDBInput,
  }
  from 'mdb-react-ui-kit';
  import { setUser
  } from '../redux/reducers/userReducer';
import {Button} from 'react-bootstrap';

function LogIn({modalShow, setModalShow}){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setErrMessage('');
    }, [email, password]);

    useEffect(() => {
        if(success){
            setErrMessage('');
        }
    }, [success])

    const signIn = async () => {
        if(email !== '' && password !== ''){
            if(validateEmail(email)){
                await dispatch(setUser({email: email}));
                setSuccess(true);
                setTimeout(() => {
                    setModalShow(false); 
                }, 1000)
            }else{
                setErrMessage('Invalid email format');
            }
        }else{
            setErrMessage('Fill in the form');
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    return(
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            data-bs-theme="dark"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <div className="text-white">Sign Up</div>
            </Modal.Header>
            <Modal.Body>
                <MDBContainer className="p-1 my-1 d-flex flex-column w-50">
                    <MDBInput value={email} onChange={(e) =>  setEmail(e.target.value)} wrapperClass='mb-4 text-white' label='Email address' id='form1' type='email'/>
                    <MDBInput value={password} onChange={(e) =>  setPassword(e.target.value)} wrapperClass='mb-4 text-white' label='Password' id='form2' type='password'/>

                    <Button className="mb-4" onClick={() => signIn()}>Sign in</Button>
                    {errMessage !== '' && <p className="text-center text-red">{errMessage}</p>}
                    {success && <p className="text-center text-green">Log in successful</p>}
                </MDBContainer>
            </Modal.Body>
        </Modal>
    )
}

export default LogIn;