import { Button, Col, Container, Row } from 'react-bootstrap';
import '../assets/css/Home.css';

import gold from '../assets/img/Gold.gif';
import card_banner from '../assets/img/card-banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { faRocketchat, faTelegram } from '@fortawesome/free-brands-svg-icons';
import {useNavigate} from 'react-router-dom';

/*
const cards=[
    {id: 0, name: 'SILVER PACKAGE', img: '/static/Silver.png', price: 500, desc: 'Silver  Package', rating: '4.95/5', bestOffer: false},
    {id: 1, name: 'PLATINUM PACKAGE', img: '/static/Platinum.png', price: 2000, desc: 'Platinum  Package', rating: '5/5', bestOffer: true},
    {id: 2, name: 'GOLD PACKAGE', img: '/static/Gold.png', price: 1500, desc: 'Gold  Package', rating: '4.99/5', bestOffer: false}
]
*/

const feq = [
    {id: 0, priority: 2, title: 'If I am outside of the United States, can I still purchase Trump  Collector Cards?', answer: "Of course! If you're unable to purchase with a credit card, international customers can use cryptocurrency."},
    {id: 1, priority: 1, title: "What is a Trump  Collector Card?", answer: "Trump  Collector Card is a digital asset that helps you earn tokens called DTCoins. With this you are collecting and supporting our program dedicated to our beloved President Donald J. Trump! <br/> This asset is a NFTs (non-fungible tokens) because each card has a unique identifier that cannot be copied, is recorded on a blockchain, and can be used to certify authenticity and ownership."},
    {id: 2, priority: 3, title: "What is the difference between the 3 cards and their cost?", answer: "We have 3 types of cards: Platinum, Silver and Gold. The difference in the price is their utility, meaning the difference in price is the amount of tokens you will earn with each staking of the cards. Platinum earns more then silver, silver more then gold etc"},
    {id: 3, priority: 4, title: "Can I buy Trump  Collector Cards with my credit card?", answer: "Yes, we’ve made it so you can buy Trump Digital Trading Cards with your credit card! We’ve made the entire purchase process as easy as making any other purchase online. You can only use one credit card to make a purchase. There will be a 4.5% service fee associated with any credit card purchase."},
    {id: 4, priority: 5, title: "Why do I need a crypto wallet to purchase Trump  Collector Cards?", answer: "Crypto wallets allow you to transfer and store your cryptocurrency and digital collectibles, like these Trump  Collector Cards. A crypto wallet is similar to having a safe for your physical collectibles or a filing cabinet for your important papers.  It’s a place to store your digital assets. <br/><br/> The good news if you don’t have a wallet is you can create one simply and quickly with your email address. It’s so easy and fast. <br/><br/> If you already have a wallet, such as a MetaMask or Coinbase Wallet, you’re ready to go. Choose “Connect Wallet” at checkout and pay with crypto as you would any other NFT/digital collectible."},
    {id: 5, priority: 6, title: "How long does it take to receive my Trump  Collector Cards in my digital wallet?", answer: "You receive your card instantly after completing the transaction. The card will be placed in your wallet and if you are new to web3 you can go through this guide on how to import it on the wallet, you can also skip this step and view it directly on the Dashboard part of this page where you can stake your cards"}
]

function Home(){
    const [active, setActive] = useState(-1);
    //const [modalShow, setModalShow] = useState(false);
    //let { ref_code } = useParams();
    const navigate = useNavigate();

    const changeActive = (id) => {
        if(active === id){
            setActive(-1)
        }else{
            setActive(id)
        }
    }
    return(
        <div className='home_section'>
            {/* BANNER */}
            <div className='banner__home'>
                <Container>
                    <Row className='row_home_banner'>
                        <Col className='display_center'>
                            <div> 
                                <h1 className='h1__home'>LIMITED EDITION OFFER</h1>
                                <p className='p__home'>This is your first & only opportunity to acquire this exclusive limited edition.</p>
                                <h6 className='h6__home'>DON'T MISS THIS GOLDEN CHANCE NOW!</h6>

                                <Button className='btn__banner' onClick={() => navigate('/dashboard')}>BUY NOW</Button>
                            </div>
                        </Col>
                        <Col className='display_center'>
                            <div>
                                <div className='display_center'>
                                    <img src={gold} alt='gold' className="gold_gif" />
                                </div>
                                <p className='p__home pt-3 pb-2' style={{textAlign: 'center'}}>
                                    TRUMP COLLECTOR  CARD <br/>
                                    <b className='stars_home'><i>★ ★ ★ ★ ★</i></b> (4.99/5)
                                </p>
                                <div className='display_center'>
                                    <img className='card_home' src={card_banner} alt='card_banner' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* HOW IT WORKS */}
            <div className='pb-5' style={{position: 'relative'}}>
                <div className='floating_bg'>
                    <div className='container__circle'>
                        <div className='circle_primary'/>
                    </div>
                </div>
                <Container style={{position: 'relative', zIndex: '10'}}>
                    <h1 className='text_black h1_cards text_center text_header'>HOW IT WORKS</h1>
                    
                    <div className='gap_row'>
                        <div className='card_home_holder'>
                            <h1 className='text_header step_home'>
                                <span className='text_1em'>STEP</span> <br className='hide_br'/> ONE
                            </h1>

                            <div className='ps-5'>
                                <h1>LOG IN</h1>
                                <hr className='hr_home' />
                                <div className='text_1em'>
                                    Once you create an account you get a digital wallet that holds your assets: <br/>
                                    - The <span className='text_primary'>TRUMP  COLLECTOR CARDS</span> you purchase<br/>
                                    - The <span className='text_primary'>TPatriot Tokens</span> from claims<br/>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className='flex_end'>
                            <div className='card_home_holder'>
                                <h1 className='text_header step_home'>
                                    <span className='text_1em'>STEP</span> <br className='hide_br'/> TWO
                                </h1>

                                <div className='ps-5'>
                                    <h1>BUY NFT COLLECTOR CARDS</h1>
                                    <hr className='hr_home' />
                                    <div className='text_1em'>
                                    After logging in select the best offer and pay with Card. <br/> 
                                    The assets will be send straight in your Digital Wallet!
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className='flex_end'>
                            <div className='card_home_holder'>
                                <h1 className='text_header step_home'>
                                    <span className='text_1em'>STEP</span> <br className='hide_br'/> TWO
                                </h1>

                                <div className='ps-5'>
                                    <h1>RECEIVE YOUR CARDS</h1>
                                    <hr className='hr_home' />
                                    <div className='text_1em'>
                                    The NFT you purchased and the tokens that come with it will be displayed on the <span className='text_primary'>Dashboard.</span><br/>
                                    You can check it everyday to see your investment!<br/>
                                    <b className='text_primary'>GOOD LUCK!</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h1 className='text_center text_header text_primary pt-5'>OWN A PIECE OF HISTORY THAT NEVER FADES!</h1>
                    <p className='p_how text_black text_center pb-5 mb-5'>
                        <span className='text_primary pe-1'>Own a piece of history, the Trump Collector  Card!</span>
                        This Golden Trump Card is a symbol of President Trump’s victory and success.
                        This <span className='text_primary pe-1'>quality-crafted</span>Golden Trump Card is 
                        <span className='text_primary ps-1'>worthy</span> of your<span className='text_primary ps-1'>investment</span> 
                        as it showcases two designs that screen <span className='text_primary'>patriotism</span> and <span className='text_primary'>greatness</span> in one.
                    </p>
                </Container>
            </div>

            {/* CARDS
            <div className='cards__home'>
                <h1 className='h1_cards'>THE TRUE SUPPORTERS PACKAGES!
                    <br/>CHOOSE WISELY!
                </h1>

                <p className='p_cards'>
                    <span className='text_red'>WARNING:</span> THIS PROMOTION IS ENDING SOON, CHOOSE WISELY! <br/>
                    <span className='text_red'>IMPORTANT:</span> USE THIS SPECIAL OFFER TODAY AND ORDER AS MANY AS YOU CAN AND HAVE YOUR LIFE TURNED AROUND!</p>
            
                <Container>
                    <Row className='row_cards'>
                        {cards.map(c => 
                            <Col className='col_cards' key={c.id}>
                                <h6>{c.name}</h6>
                                <img className='img__cards' src={c.img} alt={c.name} />
                                {c.bestOffer && 
                                    <h3>BEST OFFER</h3>
                                }
                                <h4 className='text_primary'>${c.price}</h4>
                                <p>
                                    {c.desc}
                                    <br/>
                                    <span><b className='stars_home'>★ ★ ★ ★ ★</b> {'('+c.rating+')'}</span>
                                </p>
                                <div>
                                    <Button className='btn_cards' onClick={() => setModalShow(true)}>BUY IT NOW</Button>
                                </div>
                            </Col>    
                        )}
                    </Row>
                </Container>
                
                <CheckoutCard modalShow={modalShow} setModalShow={setModalShow} ref_code={ref_code}/>
            </div> */}

            {/* FEQ */}
            <div className='feq_cont'>
                <Container style={{position: 'relative', zIndex: '10'}}>
                        <h1 className='text_black h1_cards text_center text_header pb-5'>FREQUENTLY ASKED QUESTIONS</h1>

                        <div className='container__questions'>
                            {feq.sort((x,y) => {return x.priority - y.priority}).map(f => 
                                <div key={f.id}>
                                    <div onClick={() => changeActive(f.id)} className='display_spacebetween cursor_pointer'>
                                        <div className='question_title'>{f.title}</div>
                                        <div>
                                            <FontAwesomeIcon icon={active === f.id ? faX : faPlus} color='black' />
                                        </div>
                                    </div>

                                    <div className={active === f.id ? 'question_answer display_block' : 'question_answer display_none'} dangerouslySetInnerHTML={{__html: f.answer}}></div>
                                </div>
                            )}
                        </div>
                        {/*
                        <h6 className='text_black text_center h6_questions'>Still have questions?</h6>

                        <div className='btns_container'>
                            <Button className='btn_questions'><FontAwesomeIcon className='pe-2' icon={faTelegram} /> Ask question</Button>
                            <Button className='btn_questions'><FontAwesomeIcon className='pe-2' icon={faRocketchat} /> Contact Us</Button>
                        </div>*/}
                </Container>
            </div>
        </div>
    )
}

export default Home;